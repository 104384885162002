import React, { Component } from 'react';
const NEW_COLLECTION_IMAGE_PATH = `https://venly.market/assets/images/plus-icon.svg`

const style={ 
   plus:{
       backgroundColor: "#f2e9ff",
       fontSize: "14px",
       lineHeight: "14px",
       borderRadius: "50%",
       marginTop: "15px",
       color: "#7927ff",
       cursor: "pointer",
       width: "26px",
       height: "26px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       pointerEvent: "all"
   },
   minus:{
        backgroundColor: "#f2e9ff",
        fontSize: "14px",
        lineHeight: "14px",
        borderRadius: "50%",
        marginTop: "15px",
        color: "#7927ff",
        cursor: "pointer",
        width: "26px",
        height: "26px",
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        pointerEvent: "all",
    }
}

class Stats extends Component {

    constructor(props) {
        super(props);
        
        this.onLabelChanged = this.onLabelChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onCreateNewField = this.onCreateNewField.bind(this);
        this.onRemoveField = this.onRemoveField.bind(this);
    }
    

    onLabelChanged(e)
    {
        this.props.onChange(this.props.index, e.target.value, this.props.data.value);
    }

    onValueChanged(e)
    {
        this.props.onChange(this.props.index, this.props.data.trait_type, e.target.value);
    }

    onCreateNewField()
    {
        this.props.onCreateNew();
    }

    onRemoveField()
    {
        this.props.onRemove(this.props.index);
    }

    render() {
        const { index, data, totalLength } = this.props;
        const lastItem = index === totalLength - 1;
        return (
            <div className='attribute'>
                <div className='col-12 col-md-5'>
                    <input className='form-control' onChange={this.onLabelChanged} placeholder="Label" type="text" style={{marginTop:"10px", fontSize:".875rem"}} value={data.trait_type} ></input>
                </div>
                <div className='col-12 col-md-5'>
                    <input className='form-control' onChange={this.onValueChanged} placeholder="Value" type="text" style={{marginTop:"10px", fontSize:".875rem"}} value={data.value} ></input>
                </div>
                <div className='col-12 col-md-2'>
                    <div onClick={this.onCreateNewField} style={lastItem ? style.plus : style.minus}><i className='fa fa-plus'></i></div>
                    <div onClick={this.onRemoveField} style={lastItem ? style.minus : style.plus}><i className='fa fa-minus'></i></div>
                </div>
            </div>
        );
    }
}

export default Stats;