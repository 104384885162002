export const truncateAddress = (address) => {
    if (!address) return "No Account";
    const match = address.match(
      /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
    );
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
  };
  
export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const formatCardNumber = (number) => {
  const padded = number.toString().padStart(4, '0');
  return `#${padded}`;
};

export const formatNumber = (number, decimals) => {
  let res;
  if (typeof number === 'number') {
    res = number
      .toFixed(decimals)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } else {
    res = parseFloat(number)
      .toFixed(decimals)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return res;
};

export const formatAbbreviated = (num, decimals) => {
  if (num >= 1000000) {
    return `${formatNumber(Math.round(num / 10000) / 100, decimals)}M`;
  }

  if (num >= 1000) {
    return `${formatNumber(Math.round(num / 10) / 100, decimals)}K`;
  }

  return formatNumber(num, decimals);
};

export const truncate = (input, length) =>
  input.length > length ? `${input.substring(0, length)}...` : input;