import React, { Component, memo, useState, useRef, useEffect } from 'react';

import { Modal } from "react-bootstrap";

const SuccessModal = ({
    title,
    content,
    ...props
}) => {
     
    return (
        <Modal show={true} centered className="accept-modal" backdrop="static">
            <Modal.Body>
            <div className="section-content content-body">
                <div className="col-12 align-self-center">
                    <div className="row">
                        <div className="col-12 my-2">
                            <p className="text-center"><h2>{title}</h2></p>
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center" style={{whiteSpace: 'nowrap'}}>
                        <h4>{content}</h4>
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default SuccessModal;
