import React, { Component } from 'react';
import roadmap from "../../assets/images/roadmap.png"

class RoadMap extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
    }
    render() {
        return (
            <div className="roadmap-sect">
                {/* <h2 className='text-center mb-5' style={{color: '#1f1fc3'}}>Roadmap</h2> */}
                <span className='image-loaded'>
                    <img src={roadmap} className='roadmap-img'></img>
                </span>
                <div className='roadmap-schedule1'>
                    <h3 className='roadmap-clr1'>12, May</h3>
                    <div className='schedule-text'>Pre-Sale Guestlist (only 100 slots available)</div>
                </div>
                <div className='roadmap-schedule2'>
                    <h3 className='roadmap-clr2'>20, May</h3>
                    <div className='schedule-text'>Public Sale via Opensea and Venly Marketplaces</div>
                </div>
                <div className='roadmap-schedule3'>
                    <h3 className='roadmap-clr3'>12, June</h3>
                    <div className='schedule-text'> First distribution of Crew tools, services, and rewards</div>
                </div>
                <div className='roadmap-schedule4'>
                    <h3 className='roadmap-clr4'>20, June</h3>
                    <div className='schedule-text'>Launch of our affiliate platform</div>
                </div>
                <div className='roadmap-schedule5'>
                    <h3 className='roadmap-clr5'>10, July</h3>
                    <div className='schedule-text'>Distribution of first revenue generated to our affiliates</div>
                </div>
            </div>
        );
    }
}

export default RoadMap;