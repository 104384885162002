import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Slick from '../components/Slick/Slick';
import Purchase from '../components/Purchase/Purchase';

export const PurchasePage = () => {
    return (
        <div className="main">
            <Header />
            <Slick />
            <Purchase />
        </div>
    );
}