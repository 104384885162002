import React, { Component, memo, useState, useRef, useEffect } from 'react';

import { Modal } from "react-bootstrap";
import leftlogo from "../../assets/images/leftlogo.png"
import UtilSerivce from "../../utils/util";

const DoneModal = ({
    title,
    image,
    onClose,
    ...props
}) => {

    const onConfirm = () => {
        onClose();
    }
     
    return (
        <Modal show={true} centered className="accept-modal" backdrop="static">
            <Modal.Body className='justify-content-center'>
            <div className="section-content content-body">
                <div className="col-12 align-self-center">
                    <div className='d-flex align-items-center justify-content-center'>
                        <img className="navbar-brand-sticky confirm-logo" src={image} />
                    </div>
                    <div className="row">
                        <div className="col-12 pb-3 my-4">
                            <p className="mt-0 mb-1 text-center">{title}</p>
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <button onClick={onConfirm} className="btn secondary-btn heading-xxxs mt-4">Confirm</button>
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default DoneModal;
