import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Hero1 from '../components/Hero/Hero1';
import Hero2 from '../components/Hero/Hero2';
import Hero3 from '../components/Hero/Hero3';
import RoadMap from '../components/Hero/RoadMap';
import Work from '../components/Work/Work';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

export const HomePage = () => {
    return (
            <div className="main">
                <Header />
                <Hero />
                <Hero1 />
                <Hero2 />
                <Hero3 />
                <RoadMap />
                <Work />
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
    );
}