import React, { Component } from 'react';
import marquee1Img from "../../assets/images/marquee1.png"
import marquee2Img from "../../assets/images/marquee2.png"
import marquee3Img from "../../assets/images/marquee3.png"
import marquee4Img from "../../assets/images/marquee4.png"
import marquee5Img from "../../assets/images/marquee5.png"
import marquee6Img from "../../assets/images/marquee8.jpg"
import marquee7Img from "../../assets/images/marquee9.jpg"
import marquee8Img from "../../assets/images/marquee10.jpg"
import marquee9Img from "../../assets/images/marquee11.jpg"
import marquee10Img from "../../assets/images/marquee12.jpg"


class Hero2 extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
    }
    render() {
        return (
            <div className="hero2-back">
                <div className='col-lg-12 col-md-12 col-sm-12 wrap-box'>
                    <div className='col-lg-5 col-md-5 col-sm-12 iframe-left'>
                        <h2 className='hero-title pb-8' style={{color: '#1f1fc3'}}>What is the utility ?</h2>
                        <div style={{color: '#333'}}>
                            <p>NFT Entrepreneurs can team up with Martek to provide first-to-market services to artists, producers, and brands interested in launching NFT ventures.</p>
                            <p>Members of the Martek Crew get access to Martek's secret discord channels, Martek tools, services, and rewards, as well as other interesting perks that will be released throughout the campaign.</p>
                            <p>We're also working on an innovative lead generation system that will allow us to provide Crew members priority leads and early access to genuinely incredible, first-to-market services.</p>
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-7 col-sm-12'>
                        <div className="right-box">
                            <div className='top-marquee'>
                                <img src={marquee1Img} className='marquee-img'></img>
                                <img src={marquee2Img} className='marquee-img'></img>
                                <img src={marquee3Img} className='marquee-img'></img>
                                <img src={marquee4Img} className='marquee-img'></img>
                                <img src={marquee5Img} className='marquee-img'></img>
                            </div>
                            <div className='top-marquee'>
                                <img src={marquee1Img} className='marquee-img'></img>
                                <img src={marquee2Img} className='marquee-img'></img>
                                <img src={marquee3Img} className='marquee-img'></img>
                                <img src={marquee4Img} className='marquee-img'></img>
                                <img src={marquee5Img} className='marquee-img'></img>
                            </div>
                        </div>
                        <div className="right-box">
                            <div className='bottom-marquee'>
                                <img src={marquee6Img} className='marquee-img'></img>
                                <img src={marquee7Img} className='marquee-img'></img>
                                <img src={marquee8Img} className='marquee-img'></img>
                                <img src={marquee9Img} className='marquee-img'></img>
                                <img src={marquee10Img} className='marquee-img'></img>
                            </div>
                            <div className='bottom-marquee'>
                                <img src={marquee6Img} className='marquee-img'></img>
                                <img src={marquee7Img} className='marquee-img'></img>
                                <img src={marquee8Img} className='marquee-img'></img>
                                <img src={marquee9Img} className='marquee-img'></img>
                                <img src={marquee10Img} className='marquee-img'></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero2;