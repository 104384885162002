import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Fortmatic from "fortmatic";
const INFURA_KEY = '9aa3d95b3bc440fa88ea12eaa4456161';
const FORTMATIC_KEY = 'pk_live_5A7C91B2FC585A17';
export const providerOptions = {
  // walletlink: {
  //   package: CoinbaseWalletSDK, // Required
  //   options: {
  //     appName: "Web 3 Modal", // Required
  //     infuraId: INFURA_KEY // Required unless you provide a JSON RPC url; see `rpc` below
  //   }
  // },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: INFURA_KEY // required
    }
  },
  // fortmatic: {
  //   package: Fortmatic, // required
  //   options: {
  //     key: FORTMATIC_KEY, // required,
  //     network: {
  //       rpcUrl: 'https://rpc-mainnet.maticvigil.com',
  //       chainId: 137
  //     }, // if we don't pass it, it will default to localhost:8454
  //   }
  // },
};
