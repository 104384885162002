import React, { useState, useEffect } from 'react';
import ReactTooltip from "react-tooltip";
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import api from '../../api/api'
import {
    DEFAULT_TOKEN,
    API_ENDPOINT,
} from "../../api/_keys";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux';
import * as collectionAction from '../../actions/collection';
import { bindActionCreators } from 'redux';
// import { getCollections } from '../../store/collection';
import { create } from 'ipfs-http-client';
import defaultImg from "../../assets/images/default.png"

const FALLBACK_URL = "/img/no_image.png"

const Explorer = (props) => {
    let [images, setImages] = useState([]);
    let [showCount, setShowCount] = useState(20);
    const history = useHistory();

    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    var dateTime = date+'_'+time;

    useEffect(()=>{
        api.getImages((err, res) => {
            if (!err) {
                res.unshift(defaultImg);
                setImages(res)
            }
        })
    }, [])

    const fetchMoreData = ()=>{
        setShowCount(showCount + 20)
    }

    const goDetail = (idx, image, e)=>{
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        history.push("/explorer/"+(idx==0?DEFAULT_TOKEN:image))
    }

    return (
        <section className="popular-collections-area">
            <div className="container d-flex flex-column">
                <InfiniteScroll
                    dataLength={showCount}
                    next={fetchMoreData}
                    hasMore={true}
                    >
                    <div className="row items">
                    {images?.slice(0, showCount)?.map((image, idx) =>
                        <div key={`edt_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                            <div className="card">
                                <div className="image-over" onClick={(e)=>goDetail(idx, image, e)}>
                                    <img src={idx==0?image:(API_ENDPOINT+'/uploads/'+image || FALLBACK_URL)} onError={(e) => { e.onerror = null; e.target.src=FALLBACK_URL}} className="card-img-top" alt=""/>
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                </InfiniteScroll>
            </div>
        </section>
    );
}

const mapStateToProps = ({collection})=>({collection})
const mapDispatchToProps = (dispatch) => ({
    collectionAction: bindActionCreators({...collectionAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Explorer);
