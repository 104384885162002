class UtilSerivce {
    static capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    static getWindowWidth() {
      return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
    }
  } 
  
  export default UtilSerivce;
  
  String.prototype.isEmptyText = function (title) {
    if (this.length == 0) return "Please enter First Name and Please enter Last Name."
    if (this.length == 1) return "At least 2 characters."
    return null
  }
  String.prototype.isEmptyField = function (title) {
    if (this.length == 0) return "Please fill this field."
    return null
  }
  String.prototype.stripeAmountValid = function (title) {
    if (this.length == 0) return "Please enter amount."
    if (this < 0.6) return "Stipe charge amount must be over 0.6 USD."
    return null
  }
  String.prototype.isValidPassword = function () {
    if (this.length == 0) return "Please enter password."
    if (this.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20}$/) == null) {
      return "Password must include 8 to 20 characters which contain at least one numeric digit, one special character, one uppercase and one lowercase letter"
    }
    return null
  }
  String.prototype.isValidEmail = function () {
    if (this.length == 0) return "Please enter email."
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this)) return null
    return "Please enter valid email"
  }
  String.prototype.isValidPhoneNumber = function () {
    if (this.length == 0) return "Please enter mobile number."
    if (!(/^\d+$/.test(this))) return "Invalid mobile number."
    if (this.length != 10) return "Please enter 10 digits mobile number."
    return null
  }
  String.prototype.isLengthLimit = function (length) {
    // if (this.length == 0) return "Please enter First Name and Please enter Last Name."
    if (this.length > length) return "Keyword must not exceed " + length + " characters."
    return null
  }
  Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
      return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
  });