import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { HomePage } from "../pages/home";
import { AllCollectionsPage } from "../pages/all-collections";
import { PurchasePage } from "../pages/purchase";
import { ExplorerPage } from "../pages/explorer";
import ItemDetails from "../pages/item-details";
import { connect } from "react-redux";

const hist = createBrowserHistory();

export const MyRouts = ({
  ...props
}) => {
  
  return (
    <div>
      <BrowserRouter history={hist}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/purchase" component={PurchasePage} />
          <Route exact path="/explorer" component={ExplorerPage} />
          <Route exact path="/explorer/:slug" component={ItemDetails} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
