import React, { Component } from 'react';

class Hero1 extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
    }
    render() {
        return (
            <div className="hero1-back">
                <div className='col-lg-12 col-md-12 col-sm-12 wrap-box'>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                       <iframe src="https://player.vimeo.com/video/669454652?h=7dac8d325a&amp;badge=0&amp;byline=0&amp;autopause=0&amp;autoplay=1&amp;muted=1&amp;controls=0&amp;loop=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" className="absolute top-0 right-0 left-0 bottom-0 w-full h-full iframe" title="Donut_01.mxf"></iframe>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 iframe-right">
                        <h2 className='hero-title' style={{color: '#1f1fc3'}}>What is NFT Crew ?</h2>
                        <div style={{color: '#333'}}>
                            <p>Martek, a digital agency based in Denver operating at the convergence of technology and marketing, has announced the launch of its newest Full Stack NFT Agency.</p>
                            <p>Owning one of the Martek NFT Crew 1000 membership cards will give the holder a once-in-a-lifetime opportunity to become an NFT Entrepreneur.</p>
                            <p>Martek NFT Crew members will work for hand in hand with Martek to help artists, creators, and brands to create, launch and market their NFT projects while sharing in the profits. </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero1;