import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import leftlogo from "../../assets/images/leftlogo.png"
import { networkParams } from "../../utils/networks";
import { toHex, truncateAddress, formatAbbreviated, truncate } from "../../utils/utils";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "../../utils/providerOptions";
import ReactTooltip from "react-tooltip";
import Web3 from 'web3'
import { useContracts } from '../../hooks';

const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions // required
  });

const Header = () => {

    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState();
    const [account, setAccount] = useState();
    const [balance, setBalance] = useState(0);
    const [signature, setSignature] = useState("");
    const [error, setError] = useState("");
    const [chainId, setChainId] = useState();
    const [network, setNetwork] = useState('No Network');
    const [message, setMessage] = useState("");
    const [signedMessage, setSignedMessage] = useState("");
    const [verified, setVerified] = useState();

    const [accounts, setAccounts] = useState([])
    const [web3Enabled, setWeb3Enabled] = useState(false)

    // Empty web3 instance
    let web3 = new Web3();

    const {
      connected,
      connect,
      disconnect,
      user,
      rsunBalance,
      connecting,
      isWrongNetwork,
    } = useContracts();

    const ethEnabled = async () => {

      if (typeof window.ethereum !== 'undefined') {
        // Instance web3 with the provided information from the MetaMask provider information
        web3 = new Web3(window.ethereum);
        try {
          // Request account access
          await window.ethereum.enable();
  
          return true
        } catch (e) {
          // User denied access
          return false
        }
  
      }
  
      return false;
    }

    const connectWallet = async () => {

      if (await !ethEnabled()) {
        alert("Please install MetaMask to use this dApp!");
      }

      setWeb3Enabled(true)

      // var accs = await web3.eth.getAccounts();
      // const newAccounts = await Promise.all(accs.map(async (address) => {
      //   const balance = await web3.eth.getBalance(address)
        // const tokenBalances = await Promise.all(tokenAddresses.map(async (token) => {
  
        //   const tokenInst = new web3.eth.Contract(tokenABI, token.address);
  
        //   const balance = await tokenInst.methods.balanceOf(address).call()
  
        //   return {
        //     token: token.token,
        //     balance
        //   }
        // }))
  
      //   return {
      //     address,
      //     balance: web3.utils.fromWei(balance, 'ether'),
      //     // tokens: tokenBalances
      //   }
      // }))
      // setAccounts(newAccounts)

      try {
        const provider = await web3Modal.connect();
        const library = new ethers.providers.Web3Provider(provider);
        const accounts = await library.listAccounts();
        const network = await library.getNetwork();
       console.log("provider======>", provider, accounts, network)
        setProvider(provider);
        setLibrary(library);
        if (accounts) {
          setAccount(accounts[0]);
          const balance = await web3.eth.getBalance(accounts[0])
          setBalance(web3.utils.fromWei(balance, 'ether'))
        }
        setChainId(network.chainId);
        setNetwork(network.name);
      } catch (error) {
        setError(error);
      }
    };

    const handleNetwork = (e) => {
        const id = e.target.value;
        setNetwork(Number(id));
    };
    
    const handleInput = (e) => {
        const msg = e.target.value;
        setMessage(msg);
    };

    const refreshState = () => {
        setAccount();
        setChainId();
        setNetwork("No Network");
        setMessage("");
        setSignature("");
        setVerified(undefined);
    };
    
    // const disconnect = async () => {
    //     await web3Modal.clearCachedProvider();
    //     refreshState();
    // };

    useEffect(() => {
        if (web3Modal.cachedProvider) {
          connectWallet();
        }
      }, []);
    
      useEffect(() => {
        if (provider?.on) {
          const handleAccountsChanged = (accounts) => {
            console.log("accountsChanged", accounts);
            if (accounts) setAccount(accounts[0]);
          };
    
          const handleChainChanged = (_hexChainId) => {
            setChainId(_hexChainId);
          };
    
          const handleDisconnect = () => {
            console.log("disconnect", error);
            disconnect();
          };
    
          provider.on("accountsChanged", handleAccountsChanged);
          provider.on("chainChanged", handleChainChanged);
          provider.on("disconnect", handleDisconnect);
    
          return () => {
            if (provider.removeListener) {
              provider.removeListener("accountsChanged", handleAccountsChanged);
              provider.removeListener("chainChanged", handleChainChanged);
              provider.removeListener("disconnect", handleDisconnect);
            }
          };
        }
      }, [provider]);

    // const connect = () => {

    // }
    const getButtonText = () => {
      if (isWrongNetwork) {
        disconnect();
        return `WRONG NETWORK`;
      }
      if (!connecting && user) {
        if (rsunBalance)
          return `${formatAbbreviated(rsunBalance, 2)} RSUN | ${truncate(
            user,
            6,
          )}`;
        return `${truncate(user, 6)}`;
      }
      if (connecting) {
        return `CONNECTING...`;
      }
      return `CONNECT`;
    };
    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand" href="https://martekcloud.com/" target='_blank'>
                        <img className="navbar-brand-sticky logo" src={leftlogo} />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar */}
                    <ul className="navbar-nav items mx-right">
                        <li className="nav-item">
                          <Link className="nav-link header-li" to="/">HOME</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link header-li" to="/explorer">EXPLORER</Link>
                        </li>
                        {/* <li className="nav-item mr-2">
                          <Link className="nav-link" to="/purchase">PURCHASE</Link>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link header-li" href="https://testnets.opensea.io/collection/martek" target="_blank">BUY ON OPENSEA</a>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="#" onClick={!connected ?connect:disconnect} className="btn ml-lg-auto btn-bordered-white header-btn"><i className="icon-key mr-md-2 pt-2" />{getButtonText()}</Link>
                            {connected&&<ul className="dropdown-menu" style={{display: 'none'}}>
                                <li className="nav-item text-center">Account: {truncateAddress(account)}</li>
                                <li className="nav-item text-center">Balance: {balance}</li>
                                <li className="nav-item text-center">Network: {chainId ? (network!='homestead'?network:'mainnet') : "No Network"}</li>
                            </ul>}
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;