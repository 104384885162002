import axios from "axios";
import {
    DEFAULT_TOKEN,
    API_ENDPOINT,
} from "./_keys";

export default  {

    async baseApi(sub_url, method, json_data, cb) {
        let user = localStorage.currentUser ? JSON.parse(localStorage.currentUser) : null
        try {
          let request = {
            method,
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
            }
          };
          if(user && user.jwt) {
            request.headers.Authorization = "Bearer " + user['jwt']
          }
          if (method == "POST" || method == "PUT") {
            request["body"] = JSON.stringify(json_data);
          }
          let response = await fetch(API_ENDPOINT + sub_url, request);
          let responseJson = await response.json();
          if (response.status == 200) {
            cb(null, responseJson);
          } else {
            cb(responseJson);
          }
        } catch (error) {
          cb(error);
        }
      },
    
      async baseApiWithoutAuth(sub_url, method, json_data, cb) {
        let user = localStorage.currentUser ? JSON.parse(localStorage.currentUser) : null
        try {
          let request = {
            method,
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
            }
          };
          if (method == "POST" || method == "PUT") {
            request["body"] = JSON.stringify(json_data);
          }
          let response = await fetch(API_ENDPOINT + sub_url, request);
          let responseJson = await response.json();
          if (response.status == 200) {
            cb(null, responseJson);
          } else {
            cb(responseJson);
          }
        } catch (error) {
          cb(error);
        }
      },

    async fileUpload(uri, cb) {
      try {
        const formData = new FormData();
        formData.append('files', uri);
        let response = await fetch(
          API_ENDPOINT + '/upload',
          {
            method: "POST",
            headers: {
              "Accept": "application/json"
            },
            mode: 'cors',
            body: formData,
            timeout: 30000
          }
        );
        let status = response.status;
        let responseJson = await response.json();
        if (status == 200 || status == 201) {
          cb(null, responseJson);
        } else {
          cb(responseJson.message);
        }
      } catch (error) {
        cb(error);
      }
    },

    getCollectionList(data) {
        return axios.get(`${API_ENDPOINT}/nft/nfts?batched=true&token=${DEFAULT_TOKEN}&page=${data.page}&count=${data.pageSize}&onsale=${data.onsale}&hidden=${data.hidden}`).then(res => res.data);
    },

    getImages(cb) {
        this.baseApiWithoutAuth('/metadata/getImages', 'POST', {}, cb)
    },

    saveContract(data, cb) {
        this.baseApiWithoutAuth('/metadata/saveJson', 'POST', data, cb)
    }
};