import React, { Component } from 'react';


class Hero3 extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
    }
    render() {
        return (
            <div className="hero1-back">
                <div className='col-lg-12 col-md-12 col-sm-12 wrap-box'>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <iframe src="https://player.vimeo.com/video/669454581?h=03d5c62931&badge=0&byline=0&autopause=0&autoplay=1&muted=1&controls=0&loop=1&player_id=2&app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" className="absolute top-0 right-0 left-0 bottom-0 w-full h-full iframe" title="Donut_01.mxf"></iframe>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 iframe-right">
                        <h2 className='hero-title' style={{color: '#1f1fc3'}}>What is the process ?</h2>
                        <div style={{color: '#333'}}>
                            <p>Once the NFT has been created, the owner can begin prospecting for clients.</p>
                            <p>Once a transaction has been made, he can log in to the website, add it to his dashboard, and request payment from Martek.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero3;