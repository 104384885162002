import React, { useState } from 'react';
import { ethers } from 'ethers';
import { useContracts } from '../../hooks';
import { Box, Container, Grid, Typography, Paper, Divider, FormControl, Input, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const Purchase = () => {
    
    const {
        connected,
        rsunBalance,
        rsunApproved,
        packs,
        web3Provider,
        samurai,
        purchasing,
        price,
        prvPrice,
        successfullyPurchased,
    } = useContracts();

    const purchaseUpLimit = 1000;
    const purchaseDownLimit = 1;
    const [selectedAmount, setSelectedAmount] = useState(purchaseDownLimit);
    
    const [minting, setMinting] = useState(false);
    const hasSelected = selectedAmount !== 0;

    const evaluate = (value) => {
        let val;
        if (value < purchaseDownLimit) val = purchaseDownLimit;
        else if (value > purchaseUpLimit) val = purchaseUpLimit;
        else val = value;
        setSelectedAmount(val);
    };

    const decrease = () => {
        const val = selectedAmount - 1;
        evaluate(val);
    };
    
    const increase = () => {
        const val = selectedAmount + 1;
        evaluate(val);
    };

    const getUserAddress = async () => web3Provider.provider.selectedAddress;

    const getUserBalance = async (address) =>
        web3Provider.getBalance(address);

    // get the current buy price before each mint as there is a price curve in the contract
    const getBuyPrice = async () => {
        let buyPrice = await samurai.getNFTPrice(selectedAmount);
        buyPrice = Number(
        ethers.utils.formatEther(
            ethers.BigNumber.from(parseInt(buyPrice._hex, 16).toString()),
        ),
        );
        // check if user has enough ether to buy the selected amount
        const address = await getUserAddress();
        let userBalance = await getUserBalance(address);
        userBalance = Number(
        ethers.utils.formatEther(
            ethers.BigNumber.from(parseInt(userBalance._hex, 16).toString()),
        ),
        );
        if (userBalance < buyPrice) return false; // need a toast here -- not enough eth to buy tickets
        return buyPrice;
    };

    const buy = async () => {
        // const signer = samurai.connect(web3Provider.getSigner());
        // try {
        //   setMinting(true);
        //   await signer
        //     .mint(projectId, {
        //       value: ethers.utils.parseEther(String(price / 1000.0)),
        //     })
        //     .then((res) => console.log(res));
        // } catch (e) {
        //   console.log(e);
        // }
        // setMinting(false);
        const price = await getBuyPrice();
        const ownerAddress = await samurai.owner();
        const mintAddress = ownerAddress;
        console.log('mintAddress = ', mintAddress);
        if (!price) return;
    
        const signer = samurai.connect(web3Provider.getSigner());
        signer.changePublicSaleState(true);
        console.log(price);

        // try {
        //     await signer
        //     .mintTicket(selectedAmount, mintAddress, {
        //         value: ethers.utils.parseEther(String(price)),
        //     })
        //     .then((res) => console.log(res));
        // } catch (e) {
        //     console.log('error1---->', e);
        // }

        try {
            await signer
            .mint(selectedAmount, {
              value: ethers.utils.parseEther(String(price / 1000.0)),
            })
            .then((res) => console.log('mint res--->', res));
        } catch (e) {
            console.log('error2---->', e);
        }
        
    };

    const getBuyButtonText = () => {
        if (!connected || !packs) {
          return `NOT CONNECTED`;
        }
    
        if (!hasSelected) {
          return `PLEASE SELECT AN OPTION`;
        }
    
        if (packs && packs[selectedAmount] && !packs[selectedAmount].onSale) {
          return `PACK CURRENTLY NOT AVAILABLE`;
        }
    
        if (
          packs &&
          packs[selectedAmount] &&
          rsunBalance < packs[selectedAmount].cost
        ) {
          return `INSUFFICIENT BALANCE`;
        }
    
        if (purchasing) {
          return `PURCHASING...`;
        }
    
        return `BRING ME MY SAMURAI`;
    };
   
    const generalTitleComponent = (
        <svg
          height="100"
          stroke="white"
          strokeWidth="2"
          className="text-line"
          width="100%"
        >
          <text x="50%" dominantBaseline="middle" textAnchor="middle" y="50%">
          Please mint your nft here
          </text>
        </svg>
    );

    return (
        <Container maxWidth="md" className='mt-4'>
            <Grid container justifyContent="center" direction="column">
                <Grid item xs={12}>
                    <Typography
                        variant="h2"
                        className="purchase-title"
                    >
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            {generalTitleComponent}
                        </Box>
                    </Typography>
                </Grid>
                <Paper className="card">
                    <Box p="1.5rem" textAlign="center">
                        <h3 className="mt-3 mb-4" style={{color: 'black'}}>How does it work?</h3>
                        <Typography
                        variant="h6"
                        className="purchase-comment"
                        >
                        The price follows a price curve, the price for private minting is{' '}
                            {prvPrice / 1000.0} eth and one for public minting is{' '}
                            {price / 1000.0} eth.   
                        </Typography>
                        <Box my={1} />
                    </Box>
                    <Divider className="divider" />
                    <Box
                        p="1.5rem"
                        className="box-container"
                    >
                        <Typography variant="h4" className="box-text">
                        Number of Tickets to purchase
                        </Typography>
                        <Box mt={2} mb={4}>
                            <FormControl variant="outlined" className="w-100">
                                <Grid container spacing={0}>
                                    <Grid item md={4} xs={12} />
                                    <Grid item md={4} xs={12}>
                                        <div className="amount_wrapper">
                                            <Button
                                                className="down_count"
                                                title="Down"
                                                onClick={() => decrease()}
                                            >
                                                <RemoveIcon />
                                            </Button>
                                            <div className="amount">{selectedAmount}</div>
                                            <Button
                                                className="up_count"
                                                title="Up"
                                                onClick={() => increase()}
                                                disabled={!connected}
                                            >
                                                <AddIcon />
                                            </Button>
                                            {/* <Input
                                                value={projectId}
                                                className='amount-input'
                                                onChange={handleProjectIdChange}
                                            /> */}
                                        </div>
                                        <br/>
                                        <button onClick={() => buy()} className="btn secondary-btn heading-xxxs mt-4 px-4" style={{letterSpacing: '4px'}} disabled={!connected}>MINT</button>
                                    </Grid>
                                    <Grid item md={4} xs={12} />
                                </Grid>
                            </FormControl>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Container>
    );
}

export default Purchase;