import React, { useState, useEffect, useRef } from 'react';
import {
    useParams,
    Link,
    useHistory
} from "react-router-dom";
import {
    DEFAULT_TOKEN,
    API_ENDPOINT,
} from "../../api/_keys";
import api from '../../api/api'
import Stats from './Stats'
import DoneModal from '../../components/Modal/DoneModal'
import SuccessModal from '../../components/Modal/SuccessModal'
import confetti from "canvas-confetti";
import { ethers } from 'ethers';
import { useContracts } from '../../hooks';
import { Box, Container, Grid, Typography, Paper, Divider, FormControl, Input, Button } from '@material-ui/core';
import defaultImg from "../../assets/images/default.png"
import { FileDrop } from "react-file-drop";
import "./style.css";

const categories = [
    {
        id: 0,
        name: 'Art',
    },
    {
        id: 1,
        name: 'Music',
    },
    {
        id: 2,
        name: 'Scripts',
    },
    {
        id: 3,
        name: 'Sticker Album',
    },
    {
        id: 4,
        name: 'Video',
    }
];

const ItemDetail = ({
    ...props
  }) => {

    const {
        connected,
        rsunBalance,
        rsunApproved,
        packs,
        web3Provider,
        samurai,
        purchasing,
        price,
        prvPrice,
        successfullyPurchased,
    } = useContracts();

    const inputRef = useRef(null)
    let { slug } = useParams();
    let [activate, setActivate] = useState(false);
    let [bUpload, setUpload] = useState(false);
    let [bMinted, setMinted] = useState(false);
    let [bDuplicate, setDuplicate] = useState(false);
    let [hash, setHash] = useState('');
    let [title, setTitle] = useState('');
    let [description, setDescription] = useState('');
    let [category, setCategory] = useState('Art');
    let [dropUrl, setDropUrl] = useState('');
    let [stats, setStats] = useState([{trait_type: "", value: ""}]);

    const purchaseUpLimit = 1000;
    const purchaseDownLimit = 1;
    const [selectedAmount, setSelectedAmount] = useState(purchaseDownLimit);
    const [minting, setMinting] = useState(false);
    const hasSelected = selectedAmount !== 0;
    
    useEffect(()=>{
       
    }, [])

    const throwConfetti = () => {
        confetti({
            particleCount: 400,
            spread: 70,
            origin: {y: 0.6},
        });
    }

    const handleInput = (e) => {
        if (e.target.name=='title') {
            setTitle(e.target.value)
        } else if (e.target.name=='description') {
            setDescription(e.target.value)
        } 
    }

    const getUserAddress = async () => web3Provider.provider.selectedAddress;

    const getUserBalance = async (address) =>
        web3Provider.getBalance(address);

    const getBuyPrice = async () => {
        let buyPrice = await samurai.getNFTPrice(selectedAmount);
        buyPrice = Number(
        ethers.utils.formatEther(
            ethers.BigNumber.from(parseInt(buyPrice._hex, 16).toString()),
        ),
        );
        // check if user has enough ether to buy the selected amount
        const address = await getUserAddress();
        let userBalance = await getUserBalance(address);
        userBalance = Number(
        ethers.utils.formatEther(
            ethers.BigNumber.from(parseInt(userBalance._hex, 16).toString()),
        ),
        );
        if (userBalance < buyPrice) return false; // need a toast here -- not enough eth to buy tickets
        return buyPrice;
    };

    const buy = async () => {
       
        const price = await getBuyPrice();
        if (!price) return;
        setMinting(true);
        //---- set CID  ----//
        const signer = web3Provider.getSigner();
        const signedSamurai = samurai.connect(signer);
        const adrs = await signer.getAddress();
        const totalSupply = await samurai.totalSupply();
        
        // const cid = 
            await signedSamurai.setCID(ethers.BigNumber.from(totalSupply).toNumber(), hash)
            .then(async (res) => {
                setMinted(true);
                await new Promise(r => {setTimeout(r, 2000)});
                setMinted(false);
                setMinting(false);
                throwConfetti();
            });
            // await cid.wait();
        //------------------//
        // const ownerAddress = await samurai.owner();
        // const mintAddress = ownerAddress;
        // console.log('mintAddress = ', mintAddress);
        
        // const signer = samurai.connect(web3Provider.getSigner());
        // signer.changePublicSaleState(true);
        // console.log(price);


        // try {
        //     await signedSamurai
        //     .mint(1, {
        //       value: ethers.utils.parseEther(String(price)),
        //     })
        //     .then(async (res) => {
        //         console.log('res-----:', res);
        //         setMinted(true);
        //         await new Promise(r => {setTimeout(r, 2000)});
        //         setMinted(false);
        //         throwConfetti();
        //     });
        // } catch (e) {
        //     console.log('error:', e);
        //     alert(e);
        //     return;
        // }
        // setMinting(false);
        // setActivate(false);
    };

    const onClose = () => {
        setUpload(false);
    }

    const onCreateContract = () => {
        let asset_url = API_ENDPOINT+(dropUrl.length>0?dropUrl:('/uploads/'+slug));
        var metadata;
        if (asset_url.split(".").pop()=='gif'||asset_url.split(".").pop()=='mp4'||asset_url.split(".").pop()=='webm'||asset_url.split(".").pop()=='m4v'||asset_url.split(".").pop()=='ogv'||asset_url.split(".").pop()=='ogm'||asset_url.split(".").pop()=='ogg') {
            metadata = {
                name: title,
                description: description,
                type: category,
                animation_url: asset_url,
                image: asset_url,
                attributes: stats.filter(v => v.trait_type != "" && v.value != ""),
            }
        } else {
            metadata = {
                name: title,
                description: description,
                type: category,
                image: asset_url,
                attributes: stats.filter(v => v.trait_type != "" && v.value != ""),
            }
        }
        api.saveContract({
            metadata: metadata,
            path: slug.replace(/\.[^/.]+$/, "")
        },(err, res) => {
            if (!err) {
                setHash(res.IpfsHash);
                setDuplicate(res.isDuplicate);
                setActivate(true);
                setUpload(true);
            } else {
                alert(err);
                return;
            }
        })
    }

    const getContractUrl = (chain, address, e) => {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
          }
        var url
        switch(chain) {
            case "ETHEREUM": 
                url = "https://rinkeby.etherscan.io/address/"+address+"/transactions";
                break;
            case "MATIC": 
                url = "https://polygonscan.com/address/"+address+"/transactions";
                break;
            case "BSC": 
                url = "https://bscscan.com/address/"+address+"/transactions";
                break;
        }
        window.open(url, "_blank");
    }

    const filePicker = () => {
        inputRef.current.click();
    }

    const fileHandler = (files) => {
        const extension = files[0].name.split(".")[1]?.toLowerCase();
        if (extension !== undefined) {
            api.fileUpload(files[0], (error, res) =>{
                if (error) {
                    return
                }
                setDropUrl(res[0].url)
            })
        } else {
            alert("file type not supported");
        }
    }
    
    return (
        <section className="item-details-area">
            {bUpload&&<DoneModal image={API_ENDPOINT+(dropUrl.length>0?dropUrl:('/uploads/'+slug))} onClose={onClose} title='Please connect your wallet and mint.'/>}
            {bMinted&&<SuccessModal title='Success!' content='A NFT has been minted successfully!'/>}
            <div className="container">
                <div className="text-center" style={{marginTop: 70}}>
                    Design, and mint your NFT directly into your inventory.
                </div>
                <div className="filedrop-box mt-5">
                    <div className="col-12 col-lg-5">
                        <div className="item-info">
                            {(slug === DEFAULT_TOKEN&&dropUrl.length==0)?
                            <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
                                <div className="upload">
                                    <i className="fa fa-image"></i>
                                </div>
                                <p className="placeholder">
                                    Drag & drop file here
                                </p>
                                <p className="placeholder-caption">
                                    or click to upload
                                </p>
                                <input
                                    accept=".xls, .png, .psd, .doc, .docx, .ai, .pdf, .jpg, .jpeg, gif, mp4, webm, m4v, ogv, ogm, ogg"
                                    style={{ visibility: "hidden", opacity: 0 }}
                                    ref={inputRef}
                                    type="file"
                                    onChange={(e) => fileHandler(e.target.files)}
                                />
                            </FileDrop>:
                            <div className="item-thumb text-center">
                                <img src={API_ENDPOINT+(dropUrl.length>0?dropUrl:('/uploads/'+slug))} alt="" />
                            </div>}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="content mt-5 mt-lg-0">
                            <div className='col-12 col-md-12 d-flex align-items-center mt-4' style={{fontSize:"15px", fontWeight:"700", lineHeight:"20px", color:"#4b4b5a"}}>
                                Title
                            </div>
                            <input className='form-control' placeholder="Title" name="title" type="text" style={{marginTop:"10px", marginLeft:"15px", marginRight:"15px", fontSize:".875rem"}} onChange={(e)=>handleInput(e)}></input>

                            <div className='col-12 col-md-12 d-flex align-items-center mt-4'style={{fontSize:"15px", fontWeight:"700", lineHeight:"20px", color:"#4b4b5a"}}>
                                Description
                            </div>
                            <textarea className='form-control' name="description"  placeholder="Describe your collectible" type="text" style={{marginTop:"10px", marginLeft:"15px", marginRight:"15px", resize:"vertical", fontSize:".875rem", height:"calc(1.5em + 1rem + 70px)"}} onChange={(e)=>handleInput(e)}></textarea>

                            <div className='col-12 col-md-12 d-flex align-items-center mt-4'style={{fontSize:"15px", fontWeight:"700", lineHeight:"20px", color:"#4b4b5a"}}>
                                Categories
                            </div>
                            <select className="form-control" onChange={(e)=>setCategory(e.target.value)} style={{marginTop:"10px", marginLeft:"15px", marginRight:"15px", fontSize:".875rem"}}>
                                {categories!=null&&categories.map((val)=>
                                <option value={val.name} selected={(val.name==category)?true:false}>{val.name}</option>
                                )}
                            </select>

                            <div className='col-12 col-md-12 d-flex align-items-center mt-4'style={{fontSize:"15px", fontWeight:"700", lineHeight:"20px", color:"#4b4b5a"}}>
                                <i className="fa fa-tachometer" style={{color:"#7927ff"}}></i>&nbsp;&nbsp;Attributes
                            </div>
                            {stats && stats.map((stat, index) => {
                                return (
                                    <Stats key={index} data={stat} totalLength={stats.length} index={index} 
                                    onChange={(key, trait_type, value)=>{
                                        const pStats = [...stats];
                                        pStats[key].value = value;
                                        pStats[key].trait_type = trait_type;
                                        setStats(pStats);
                                    }} 
                                    onCreateNew={()=>{
                                        const pStats = [...stats];
                                        pStats.push({trait_type:"", value:""});
                                        setStats(pStats);
                                    }}
                                    onRemove={(index)=>{
                                        const pStats = [...stats];
                                        if (index !== -1) {
                                            pStats.splice(index, 1);
                                            setStats(pStats);
                                        }
                                    }}/>    
                                );
                            })}

                            <div className='col-12 col-md-12 d-flex justify-content-center align-items-center mt-4' style={{fontSize:"15px", fontWeight:"700", lineHeight:"20px", color:"#4b4b5a"}}>
                                {(activate&&connected) ? 
                                <a className={["d-block btn btn-bordered-white mt-4", minting?'disabled':''].join(" ")} href="#" onClick={buy} hidden={minting?true:false}>{minting?'Minting...':'Mint NFT'}</a>:
                                <a className={["d-block btn btn-bordered-white mt-4", title.length==0?'disabled':''].join(" ")} onClick={onCreateContract}>Create Contract</a>
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ItemDetail