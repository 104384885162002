import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Slick from '../components/Slick/Slick';
import Explorer from '../components/Explore/Explorer';

export const ExplorerPage = () => {
    return (
        <div className="main">
            <Header />
            <Slick />
            <Explorer />
        </div>
    );
}